.textarea-preview-container {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
  color: var(--text-color-secondary);
}

.textarea-preview {
  background-color: var(--button-background);
  border: var(--jenkins-border--subtle);
  padding: var(--form-input-padding);
  margin-top: 0.5rem;
  border-radius: var(--form-input-border-radius);
}
