@use "../abstracts/mixins";

.page-header {
  display: flex;
  align-items: center;
  height: 3.5rem;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  background-color: var(--header-bg-classic);
}

.page-header > * {
  margin-right: 0.75rem;
}

.page-header__brand {
  display: inline-block;
  height: 3.5rem;
  position: relative;
  flex: 1; // push controls to the end of the block
}

// Need to use the element selector to increase weight otherwise it will be overriden by the
// a:visited selector if it is declared later
// Only styled by the overrides with the new UI enabled
a.page-header__brand-link {
  display: none;
}

.page-header__brand-name {
  color: inherit;
}

.page-header__brand-image {
  height: 2rem;
  width: 1.5rem;
  margin-right: 0.75rem;
}

.page-header__am-wrapper {
  display: contents;
}

.page-header__hyperlinks {
  display: flex;
  align-items: center;
}

.page-header__hyperlinks > a,
.page-header__hyperlinks > button,
.am-container > a {
  @include mixins.item;

  --text-color: var(--header-link-color);

  display: inline-flex;
  align-items: center;
  appearance: none;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  color: var(--text-color);
  text-decoration: none;
  padding: 0.5rem;
  margin-right: 0 !important;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  &::before,
  &::after {
    inset: 0 !important;
  }

  .jenkins-menu-dropdown-chevron {
    position: relative;
    top: unset !important;
    right: unset !important;
    margin-left: 0.5rem;

    &::after {
      opacity: 1;
    }
  }
}

.page-header__hyperlinks a span {
  &:not(:first-child) {
    margin-left: 0.25rem;
  }
}
