.jenkins-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 0.6875rem;
  font-weight: 400;
  min-height: 20px;
  min-width: 20px;
  padding: 0 0.4rem;
  background: color-mix(
    in sRGB,
    var(--text-color-secondary) 12.5%,
    transparent
  );

  &[class*="color"] {
    background: color-mix(in sRGB, var(--color) 85%, transparent);
    color: var(--white) !important;
    box-shadow: inset 0 -1px 2px var(--color, var(--text-color-secondary));
    text-shadow: 0 1px 1px rgb(0 0 0 / 0.1);
    backdrop-filter: blur(2.5px);
  }
}
