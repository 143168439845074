.jenkins-input {
  display: block;
  background: var(--input-color);
  border: var(--jenkins-border-width) solid var(--input-border);
  padding: var(--form-input-padding);
  border-radius: var(--form-input-border-radius);
  width: 100%;
  min-height: 2.375rem;
  box-shadow: var(--form-input-glow);

  // Set height transition to 0s as vertical resizing has a delay/lag otherwise
  transition:
    all var(--standard-transition),
    height 0s,
    padding 0s;

  &:not(:disabled) {
    &:hover {
      border-color: var(--input-border-hover);
    }

    &:active,
    &:focus {
      outline: none;
      border-color: var(--focus-input-border);
      box-shadow: var(--form-input-glow--focus);
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}

input,
textarea,
select {
  color: var(--text-color);
  background-color: var(--input-color);
  font-family: inherit;
}
