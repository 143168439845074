$card-padding: 1rem;
$icon-size: 1.375rem;

.jenkins-card {
  position: relative;
  border-radius: 1rem;
  margin-bottom: calc(var(--section-padding) / 2);
  background: var(--card-background);

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $card-padding;
    height: 50px;
    font-size: var(--font-size-sm) !important;
    font-weight: var(--font-bold-weight);
    width: 100%;
    z-index: 1;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    margin-right: -0.2rem;
  }

  &:not(:hover) {
    .jenkins-card__unveil,
    .jenkins-card__reveal {
      color: var(--text-color-secondary);
    }
  }

  @media (prefers-contrast: more) {
    .jenkins-card__unveil,
    .jenkins-card__reveal {
      color: var(--text-color) !important;
    }
  }

  &:hover {
    .jenkins-card__reveal {
      color: var(--text-color);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 0 $card-padding $card-padding;

    &:empty {
      display: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    border: var(--card-border-width) solid var(--card-border-color);
    z-index: 1;
    pointer-events: none;
  }

  .jenkins-card__reveal {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block: -0.5rem;
    min-height: 0;
    padding: 0;
    width: 26px;
    height: 26px;
    border-radius: 0.33rem;
    transition:
      scale var(--standard-transition),
      opacity var(--standard-transition);

    svg {
      width: 1rem;
      height: 1rem;
      transition: color var(--standard-transition);
    }

    &::before,
    &::after {
      opacity: 0;
    }

    &:hover {
      opacity: 0.75;
    }

    &:active {
      scale: 95%;
      opacity: 0.5;
    }
  }

  hr {
    align-self: stretch;
    border: none;
    border-top: var(--card-border-width) solid var(--text-color-secondary);
    opacity: 0.1;
    margin: 0;
  }
}

.jenkins-card__details {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.75rem;
}

.jenkins-card__details__item {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.75rem;
  font-weight: normal;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: start;
    width: $icon-size;
    height: 1lh;

    svg {
      width: $icon-size;
      height: $icon-size;
      color: var(--text-color);
    }
  }
}
